import cultureIcon from "../../../images/advantagesIcons/icon-culture.svg";
import cultureIconActive from "../../../images/advantagesIcons/icon-culture-active.svg";
import cultureIconMobile from "../../../images/advantagesIcons/icon-culture-mobile.svg";
import timeIcon from "../../../images/advantagesIcons/icon-timezone.svg";
import timeIconActive from "../../../images/advantagesIcons/icon-timezone-active.svg";
import timeIconMobile from "../../../images/advantagesIcons/icon-timezone-mobile.svg";
import costsIcon from "../../../images/advantagesIcons/icon-costs.svg";
import costsIconActive from "../../../images/advantagesIcons/icon-costs-active.svg";
import costsIconMobile from "../../../images/advantagesIcons/icon-costs-mobile.svg";
import coordinationIcon from "../../../images/advantagesIcons/icon-coordination.svg";
import coordinationIconActive from "../../../images/advantagesIcons/icon-coordination-active.svg";
import coordinationIconMobile from "../../../images/advantagesIcons/icon-coordination-mobile.svg";
import efficiencyIcon from "../../../images/advantagesIcons/icon-efficiency.svg";
import efficiencyIconActive from "../../../images/advantagesIcons/icon-efficiency-active.svg";
import efficiencyIconMobile from "../../../images/advantagesIcons/icon-efficiency-mobile.svg";

export const cards = [
    {
      name: "Cultural approximation",
      title: "Keep your friends close and your cultural acquaintances closer.",
      description:
        "While it is good to broaden your horizons when it comes to new work or social cultures, it is always easier to work with what you know and expect.",
      icon: cultureIcon,
      iconActive: cultureIconActive,
      iconMobile: cultureIconMobile
    },
    {
      name: "Similar time zone",
      title: "Because no one wants to wake up at 1:00am for a meeting.",
      description:
        "Good communication within a project team is key, therefore, working alongside a development team that shares your time zone is one of the great advantages of nearshore hiring.",
      icon: timeIcon,
      iconActive: timeIconActive,
      iconMobile: timeIconMobile
    },
    {
      name: "Costs reduction",
      title: "Hiring in Portugal can provide a equal or better quality service at a lower price.",
      description:
        "Portugal has a low cost of living. You can eat lunch for €12 or less (including coffee and dessert). Lisbon is one of the most accessible capitals in Western Europe.",
      icon: costsIcon,
      iconActive: costsIconActive,
      iconMobile: costsIconMobile
    },
    {
      name: "Better coordination",
      title: "Good coordination is essential for projects to succeed",
      description:
        "Thanks to advances in communication technology and the greats infrastructures in Portugal, communicate and coordinate with your teams as never been so easy",
      icon: coordinationIcon,
      iconActive: coordinationIconActive,
      iconMobile: coordinationIconMobile
    },
    {
      name: "Greater efficiency",
      title: "Working hard doesn't mean working better.",
      description:
        "Smart and hard-working people make up the Portuguese workforce. Team efficiency is essential and very important.",
      icon: efficiencyIcon,
      iconActive: efficiencyIconActive,
      iconMobile: efficiencyIconMobile
    },
  ];