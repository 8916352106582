import React from "react";
import Button from "../button";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const FooterWrapper = styled.div`
  display: flex;
  padding: 10rem 20rem 10rem 10rem;
  height: 15rem;
  align-items: center;
`;

const RightContainer = styled.div`
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  width: 16rem;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <LogoWrapper>
        <StaticImage
          src="../../images/img-logo-big@3x.webp"
          alt="logo"
          className="logo"
        ></StaticImage>
      </LogoWrapper>
      <RightContainer>
        <h3>Powered by</h3>
        <h2 style={{ margin: "0.8rem 0 2.4rem 0" }}>CAIXA MÁGICA SOFTWARE</h2>
        <a href="https://caixamagica.pt/">
          <Button content="Visit us here" font="1.8rem" bg="--accent" />
        </a>
      </RightContainer>
    </FooterWrapper>
  );
};

export default Footer;
