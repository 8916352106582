import React, { Component } from "react";
import styled from "styled-components";
import "../../../components/font-awesome";
import { Link } from "gatsby";
import NewsArticle from "../../news_article";
import Button from "../../button";
import MobileNewsArticle from "../../mobile/mobileNewsArticle";

const NewsPreviewContainer = styled.div`
  margin-top: ${(props) => (props.mobile ? "" : "5rem")};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.mobile ? "center" : "")};
  flex-direction: column;

  .slick-track {
    margin-left: 0;
  }
`;

const NewsContainer = styled.div`
  display: ${(props) => (props.mobile ? "flex" : "grid")};
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  flex-direction: ${(props) => (props.mobile ? "column" : "")};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4rem;

  .button {
    padding: 1.4rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--ultramarine);
    cursor: pointer;
  }
`;

export default class NewsPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
    };
  }

  render() {
    return (
      <NewsPreviewContainer mobile={this.props.mobile}>
        <NewsContainer
          mobile={this.props.mobile}
          className="news-preview-container"
        >
          {!this.props.mobile &&
            this.state.data.nodes
              .slice(0, 3)
              .map((article) => (
                <NewsArticle
                  lineBreaker={2}
                  article={article}
                  key={article.id}
                  home={true}
                />
              ))}
          {this.props.mobile &&
            this.state.data.nodes
              .slice(0, 3)
              .map((article) => (
                <MobileNewsArticle article={article} key={article.id} />
              ))}
        </NewsContainer>
        <ButtonsWrapper>
          <Link to="/news/">
            <Button content="See all news" font="1.8rem" bg="--ultramarine" />
          </Link>
        </ButtonsWrapper>
      </NewsPreviewContainer>
    );
  }
}
