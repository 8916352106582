import React from "react";
import styled from "styled-components";
import Button from "../../button";
import ContentPage from "../contentPage";
import bgImg from "../../../images/image-for-shore@3x.webp";

const LandingContainer = styled.div`
  background-image: url(${bgImg});
  background-size: cover;
  position: relative;

  svg {
    height: 100%;
    width: 100%;
  }
  .shape-container {
    height: 15vh;
    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    left: 0;
    bottom: 0;
  }

  path {
    stroke: none;
    fill: white;
  }
`;

const HomeContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;

  h1 {
    color: var(--ultramarine);
  }

  .p1 {
    margin: 1.6rem 0 2.4rem 0;
    max-width: 70%;
  }
`;

const LandingContent = () => {
  return (
    <LandingContainer>
       <div className="shape-container">
          <svg viewBox="0 0 500 150" preserveAspectRatio="none">
            <path d="M-181.72,-480.30 C36.11,436.47 220.09,-76.68 602.71,13.12 L500.00,150.00 L0.00,150.00 Z"></path>
          </svg>
        </div>
      <ContentPage smaller={true}>
        <HomeContainer>
          <h1>Nearshore</h1>
          <p className="p1">
            <strong>Because every path leads to Portugal.</strong> <br />
            The world is going through a though phase, forcing companies to
            adopt a new remote work culture. However, you may take this as an
            opportunity to improve the way you hire.
          </p>
          <Button content="Let's work together" font="1.8rem" bg="--accent" className="contact"/>
        </HomeContainer>
      </ContentPage>
    </LandingContainer>
  );
};

export default LandingContent;
