import React from "react";
import styled from "styled-components";
import bgImg from "../../images/image-for-shore@3x.webp";
import Button from "../button";
import ReadMore from "../readMore";

const LandingContainer = styled.div`
  background-image: url(${bgImg});
  background-size: cover;
  position: relative;
`;

const HomeContainer = styled.div`
  height: calc(100vh - 8rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  h1 {
    color: var(--ultramarine);
    font-size: 2.8rem;
  }

  .p1 {
    font-size: 1.6rem;
    margin: 1.6rem 0 2.4rem 0;
    color: var(--cobalt);
    max-width: 70%;
  }
`;

const TitleBox = styled.div`
  height: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url(${bgImg});
  background-size: cover;
 */
  h1 {
    margin: 0 5rem;
    text-align: center;
    font-weight: bold;
    max-width: 25rem;
  }
`;

const ContentBox = styled.div`
  
  background-color: white;
  border-radius: 50px 50px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  flex: 1;
 /*  bottom: 0;
  position: absolute; */

  .heading {
    font-weight: bold;
    margin: 5vh 8rem 1.6rem 8rem;
    text-align: center;
    font-size: 2rem !important;
  }

  .text {
    margin: 0 !important;
  }
`;

const MobileLandingContent = () => {
  return (
    <LandingContainer>
      <HomeContainer>
        <TitleBox>
          <h1>Nearshore in Portugal</h1>
        </TitleBox>
        <ContentBox>
          <p className="p1 heading">Because every path leads to Portugal.</p>
          <p className="p1 text">
            The world is going through a though phase, forcing companies to
            adopt a new remote work culture. <br />
            <br />
            However, you may take this as an opportunity to improve the way you
            hire.
          </p>
          <Button
            content="Let's work together"
            font="1.6rem"
            bg="--accent"
            className="contact mobile"
            margin="4vh 0 4vh 0"
          />
          <ReadMore location="#nearshore_content"/>
        </ContentBox>
      </HomeContainer>
    </LandingContainer>
  );
};

export default MobileLandingContent;
