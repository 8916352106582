import WhyPortugalContent from "../../components/homePage/whyportugal/whyportugal";
import AreaOfActionContent from "../../components/homePage/areaOfAction/areaOfAction";
import NewsSliderContent from "../../components/homePage/newsSlider/news";
import "../../components/font-awesome";
import * as React from "react";
import styled from "styled-components";
import MobileLandingContent from "./mobileLandingContent";
import MobileNearShoreContent from "./mobileNearshoreContent";
import MobileAdvantagesContent from "./mobileAdvantagesContent";
import MobileFooter from "./mobileFooter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 8rem;
  scroll-behavior: smooth;
`;

const MainContent = styled.div`
  flex: 1;

  .sectionWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rectangle {
    position: absolute;
    z-index: -1;
  }
`;

const MobileHome = () => {
  return (
    <div className="mobileHome">
      <Container>
        <MainContent className="main_content">
          <MobileLandingContent />
          <MobileNearShoreContent />
          <MobileAdvantagesContent />
          <WhyPortugalContent mobile={true}/>
          <AreaOfActionContent mobile={true}/>
          <NewsSliderContent mobile={true}/>
        </MainContent>
        <MobileFooter />
      </Container>
    </div>
  );
};

export default MobileHome;

