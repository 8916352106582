import "../components/font-awesome";
import * as React from "react";
import Layout from "../components/layout";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DesktopHome from "../components/desktop/desktopHome";
import { useMediaQuery } from "react-responsive";
import MobileHome from "../components/mobile/mobileHome";
import useSiteMetadata from "../hooks/useSiteMetadata";
import Seo from "../components/seo";
import styled from "styled-components";

const SeoWrapper = styled.div``;

const isBrowser = () => typeof window !== "undefined";

//Mobile Navbar scroll
var prevScrollpos = isBrowser() && window.pageYOffset;
if (isBrowser()) {
  window.onscroll = function () {
    if (document.getElementById("mobileNav")) {
      var nav = document.getElementById("mobileNav");
      var currentScrollPos = isBrowser() && window.pageYOffset;
      if (prevScrollpos > currentScrollPos || currentScrollPos < 100) {
        nav.style.top = "0";
      } else {
        nav.style.top = "-8rem";
      }
      prevScrollpos = currentScrollPos;
    }
  };
}

if (isBrowser()) {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const sections = document.getElementsByClassName("sectionWrapper");
  const navLi = document.getElementsByClassName("nav-item");

  function offset(el) {
    var rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  function scrollFunc() {
    //Know if user is on homepage and give this window a scroll event to track current link
    var urlSplit = window.location.href.split("/");
    var homeUrl = urlSplit[urlSplit.length - 1];
    if (homeUrl === "" || homeUrl.startsWith("#")) {
      for (var i = 0; i < sections.length; i++) {
        const sectionTop = offset(sections[i]).top;
        const sectionHeight = sections[i].clientHeight;
        if (
          window.pageYOffset >= sectionTop - sectionHeight / 3 &&
          window.pageYOffset !== 0
        ) {
          current = sections[i].getAttribute("id");
        }
      }
      for (var j = 0; j < navLi.length; j++) {
        navLi[j].classList.remove("active");
        if (navLi[j].classList.contains(current)) {
          navLi[j].classList.add("active");
        }
      }
    }
  }

  var current = "nearshore_content";
  window.addEventListener("scroll", scrollFunc);
}

const HomePage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });
  const { siteName } = useSiteMetadata();
  return (
    <SeoWrapper>
      <Seo title={siteName + " | Home"} />
      <Layout>{!isMobile ? <DesktopHome /> : <MobileHome />}</Layout>
    </SeoWrapper>
  );
};

export default HomePage;
