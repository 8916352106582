import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Button from "../../button";
import ContentPage from "../contentPage";
import Card from "./advantagesCards";
import { cards } from "../advantages/cardDetails";

const AdvantagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  .advantages-left-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .advantages-right-container {
    display: flex;
    margin-left: 2rem;
  }

  .left-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right-cards {
    margin-left: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .p2 {
    margin: 0;
  }
`;

class AdvantagesContent extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      title: cards[0].title,
      description: cards[0].description,
      cardActive: cards[0].name,
    };
  }

  handleClick(card) {
    try {
      const cardInArray = cards.find((c) => c.name === card);
      this.setState({
        title: cardInArray.title,
        description: cardInArray.description,
        cardActive: card,
      });
    } catch {
      this.setState({
        title: "",
        description: "",
        cardActive: card,
      });
    }
  }

  render() {
    return (
      <ContentPage>
        <div id="advantages_content" className="sectionWrapper">
          <AdvantagesContainer>
            <div className="advantages-left-container">
              <h3>Advantages</h3>
              <h2>Why choose this solution?</h2>
              <p className="p2">{this.state.title}</p>
              <p className="p3">{this.state.description}</p>
              <Button
                bg="--accent"
                font="1.8rem"
                content="Let’s get to work!"
                className="contact"
              />
            </div>
            <div className="advantages-right-container">
              <div className="left-cards">
                {cards.slice(0, 2).map((card) => (
                  <Card
                    text={card.name}
                    image={
                      this.state.cardActive === card.name
                        ? card.iconActive
                        : card.icon
                    }
                    active={this.state.cardActive === card.name ? true : false}
                    click={this.handleClick.bind(this, card.name)}
                    key={card.name}
                  />
                ))}
              </div>
              <div className="right-cards">
                {cards.slice(2, 5).map((card) => (
                  <Card
                    text={card.name}
                    image={
                      this.state.cardActive === card.name
                        ? card.iconActive
                        : card.icon
                    }
                    active={this.state.cardActive === card.name ? true : false}
                    click={this.handleClick.bind(this, card.name)}
                    key={card.name}
                  />
                ))}
              </div>
            </div>
          </AdvantagesContainer>
        </div>
      </ContentPage>
    );
  }
}

export default AdvantagesContent;
