import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .reason{
    color: ${(props) => props.mobile ? "var(--accent) !important" : ""};
  }
`;

const SliderWrapper = styled.div`
  width: ${(props) => props.mobile ? "80vw" : "50vw"};
  background-color: #f0eeee;
  margin-bottom: 50px;
  border-radius: 24px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
  //overflow: hidden;

  .slick-list{
    position: relative;
  display: block !important;
  overflow: hidden;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    bottom: -40px;
  }

  li {
    height: 16px !important;
    width: 16px !important;
    margin-right: 24px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  li.slick-active .dot {
    height: 16px !important;
    width: 16px !important;
    background-color: var(${(props) => props.color});
  }

  .dot {
    height: 10px !important;
    width: 10px !important;
    border-radius: 50%;
    background-color: rgba(25, 9, 73, 0.12);
    max-width: 100px;
  }
`;

const SliderElement = styled.div`
  width: 100%;
  
  img{
    object-fit: contain !important;
    border-radius: 24px;
  }

`;

class WhyPortugalSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      description: "",
      color: "",
      reasons: [
        {
          name: "We are talented",
          description:
            "History shows that Portuguese are flexible and always love a challenge. Those qualities also translate to our professionals.",
          image: this.props.data.talentImg.childImageSharp.gatsbyImageData,
          color: "--blue",
        },
        {
          name: "We are a peaceful country",
          description:
            "Portugal is rated as the 4th world’s most peaceful country. At least when we are not watching football...",
          image: this.props.data.peaceImg.childImageSharp.gatsbyImageData,
          color: "--green",
        },
        {
          name: "We are  proficient in english",
          description:
            "Portugal is rated as the 7th country with highest proficiency in English skills by EPI. Communication is no problem for us!",
          image: this.props.data.englishImg.childImageSharp.gatsbyImageData,
          color: "--accent",
        },
        {
          name: "We are a popular destination",
          description:
            "Portugal is known for its warm and welcoming people due to our history and culture. We also have a Mediterranean climate and our weather is mild. Visit us and try one of our beaches!",
          image: this.props.data.beachImg.childImageSharp.gatsbyImageData,
          color: "--red",
        },
        {
          name: "Our beer is great!",
          description:
            "Not convinced yet? Our average beer prices are one of the lowest in Europe. Portugal is also known for being one of Europe’s biggest exporters. Just go ahead, order one!",
          image: this.props.data.beerImg.childImageSharp.gatsbyImageData,
          color: "--mango",
        },
      ],
      whyPortugalSettings: {
        dots: true,
        customPaging: function (i) {
          return <div className="dot"></div>;
        },
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (i) => this.handleChange(i),
      },
    };
  }

  componentDidMount(){
    this.setState({
      reason: this.state.reasons[0].name,
      description: this.state.reasons[0].description,
      color: this.state.reasons[0].color,
    });
  }

  handleChange(index) {
    this.setState({
      reason: this.state.reasons[index].name,
      description: this.state.reasons[index].description,
      color: this.state.reasons[index].color,
    });
  }

  render() {
    return (
      <Container mobile={this.props.mobile}>
        <SliderWrapper mobile={this.props.mobile} color={this.state.color}>
          <Slider {...this.state.whyPortugalSettings}>
            {this.state.reasons.map((reason) => (
              <SliderElement key={reason.name}>
                <GatsbyImage
                  image={reason.image}
                  className="slider-img"
                  alt={"Image for slide: " + reason.name}
                />
              </SliderElement>
            ))}
          </Slider>
        </SliderWrapper>
        <p className="p2 reason">{this.state.reason}</p>
        <p className="p3" style={{ maxWidth: "450px", textAlign: "center" }}>
          {this.state.description}
        </p>
      </Container>
    );
  }
}

export default WhyPortugalSlider;

