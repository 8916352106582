import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import ContentPage from "../homePage/contentPage";

const NearshoreContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .split {
    width: 16px;
    border: solid 1px lightgray;
  }

  h2 {
    margin-bottom: 0;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .highlight {
    color: var(--accent);
  }
`;

const ImgWrapper = styled.div`
  margin: 2.5rem 0 3.5rem 0;
`;

const MobileNearShoreContent = () => {
  return (
    <ContentPage mobile="true">
      <div id="nearshore_content" className="sectionWrapper">
        <NearshoreContentWrapper>
          <h3>Nearshore</h3>
          <h2>
            A jump in the era of digital <br />
            transformation
          </h2>
          <ImgWrapper>
            <StaticImage
              src="../../images/img-nearshore@3x.webp"
              alt="Nearshore image"
            />
          </ImgWrapper>
          <TextWrapper>
            <p>
              <span className="highlight">Nearshore</span> is a type of
              outsourcing practised by a large amount of companies in the
              technology field.
            </p>
            <span className="split" />
            <p>
              It’s strategy usually involves clients in{" "}
              <span className="highlight">larger economies</span> working with
              various providers in smaller nations in a nearby country.
            </p>
            <span className="split" />
            <p>
              They often <span className="highlight">share a border</span>,
              aswell as some other things, such as the{" "}
              <span className="highlight">
                time zone, culture or social linkages
              </span>
              .
            </p>
          </TextWrapper>
        </NearshoreContentWrapper>
      </div>
    </ContentPage>
  );
};

export default MobileNearShoreContent;
