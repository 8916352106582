import React from "react";
import { Component } from "react";
import "../../../styles/salary-container.css";
import GraphElement from "./graph-element";
import styled from "styled-components";

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

//Associate countries with graph color
const countryColors = new Map();
countryColors.set("Portugal", "--blue");
countryColors.set("Spain", "--green");
countryColors.set("France", "--mango");
countryColors.set("Germany", "--red");
const gradientColors = new Map();
gradientColors.set("Portugal", "#8272ee");
gradientColors.set("Spain", "#96e0d2");
gradientColors.set("France", "#ffd22b");
gradientColors.set("Germany", "#f76b5c");

class SalaryGraph extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      data: this.props.data.allStrapiSalaries.nodes,
      allSalaries: [],
      jobActive: 0,
    };
  }

  componentDidMount() {
    this.update();
  }

  update() {
    let jobSalaries = [];
    let salaries = [];
    let data = this.state.data;
    while (data.length > 0) {
      let currentJob = data[0].job_position.position;
      data = data.filter((d) => {
        if (d.job_position.position === currentJob) {
          jobSalaries.push({
            job: d.job_position.position,
            country: d.country.country,
            salary: d.salary,
            id: d.id,
            color: countryColors.get(d.country.country),
            gradientColor: gradientColors.get(d.country.country),
          });
          return false;
        }
        return true;
      });
      salaries.push(jobSalaries);
      jobSalaries = [];
    }
    this.setState({
      allSalaries: salaries,
    });
  }

  handleClick(job) {
    this.setState({
      jobActive: job,
    });
  }

  sum(prev, next) {
    return prev + next;
  }

  salaryAmount(item) {
    return item.salary;
  }

  salaryPercentage(salaries, elementSalary) {
    let allSalaries = salaries.map(this.salaryAmount).reduce(this.sum);
    console.log((100 * elementSalary) / allSalaries);
    return (100 * elementSalary) / allSalaries;
  }

  render() {
    return (
      <div className="container">
        <p className="p4">
          Comparison between the average annual gross salary in Portugal and in
          the countries in our area of action
        </p>
        <div className="salary-graph">
          {this.state.allSalaries.length !== 0 &&
            this.state.allSalaries[this.state.jobActive].map((element) => (
              <GraphElement
                country={element.country}
                size={this.salaryPercentage(
                  this.state.allSalaries[this.state.jobActive],
                  element.salary
                )}
                salary={element.salary}
                color={element.color}
                gradientColor={element.gradientColor}
                key={element.id}
              />
            ))}
        </div>
        <ButtonsWrapper>
          {this.state.allSalaries.map((s, i) => (
            <button
              key={"button" + i}
              className={
                this.state.jobActive === i
                  ? "active-btn graph-btn"
                  : "graph-btn"
              }
              onClick={(e) => this.handleClick(i)}
            >
              {this.state.allSalaries.length !== 0 &&
                this.state.allSalaries[i][0].job}
            </button>
          ))}
        </ButtonsWrapper>
      </div>
    );
  }
}

export default SalaryGraph;
