import React from "react";
import styled from "styled-components";
import ContentPage from "../homePage/contentPage";
import { cards } from "../homePage/advantages/cardDetails";
import Button from "../button";

const AdvantagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Card = styled.div`
  background-color: white;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 3.5rem;
  padding: 3rem 2rem;
  border-radius: 1.4rem;
  display: flex;
  flex-direction: column;

  img {
    width: 3.4rem;
    height: 3.4rem;
  }

  .name {
    font-size: 2rem;
  }

  .split {
    width: 18px;
    border: solid 2px var(--text);
    border-radius: 5px;
  }

  .title {
    color: var(--accent);
  }

  .description {
    margin: 0;
  }
`;

const MobileAdvantagesContent = () => {
  return (
    <ContentPage mobile="true" background="var(--background2)">
      <div id="advantages_content" className="sectionWrapper">
        <AdvantagesWrapper>
          <h3>Advantages</h3>
          <h2>Why choose this solution?</h2>
          {cards.map((card) => (
            <Card key={card.name}>
              <img src={card.iconMobile} alt={card.name + "icon"}></img>
              <p className="name">{card.name}</p>
              <span className="split"></span>
              <p className="title">{card.title}</p>
              <p className="description">{card.description}</p>
            </Card>
          ))}
          <Button
            content="Let's work together"
            font="1.6rem"
            bg="--accent"
            className="contact mobile"
          />
        </AdvantagesWrapper>
      </div>
    </ContentPage>
  );
};

export default MobileAdvantagesContent;
