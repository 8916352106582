import React from "react";
import styled from "styled-components";

const Element = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0;
  width: 25%;

  .salary {
    font-size: 1.2rem;
    margin: 20px 0;
    font-weight: 500;
    text-align: center;
    color: var(--blue);
  }

  .country {
    font-size: 1.4rem;
    margin: 10px 0 0 0;
    font-weight: 500;
    text-align: center;
  }

  span {
    border: solid 1px black;
    width: 100%;
  }
`;

const Cube = styled.div(
  (props) => `
  width: 3.2rem;
  height: ${props.size / 3}rem;
  border-radius: 4px 4px 0 0;
  background-image: linear-gradient(to bottom, ${props.gradientColor}, var(${
    props.color
  }));
`
);

const GraphElement = ({ country, salary, size, color, gradientColor }) => {
  return (
    <Element>
      <p className="salary">{salary}K</p>
      <Cube size={size} color={color} gradientColor={gradientColor}></Cube>
      <span></span>
      <p className="country">{country}</p>
    </Element>
  );
};

export default GraphElement;
