import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../components/font-awesome";
import { Link } from "gatsby";
import NewsArticle from "../../news_article";
import Button from "../../button";

const NewsSliderWrapper = styled.div`
  margin-top: ${(props) => (props.mobile ? "" : "5rem")};
  width: 100%;

  .slick-track {
    margin-left: 0;
  }
`;

const SliderContainer = styled.div`
  max-width: ${(props) => (props.mobile ? "80vw" : "")};
  width: 100%;
  overflow: hidden;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4rem;

  .button {
    padding: 1.4rem 1.5rem;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--ultramarine);
    cursor: pointer;
  }

  .prev {
    background-color: #dedede;
    color: var(--blue);
  }

  .next {
    color: white;
  }
`;

export default class NewsSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.state = {
      data: this.props.data,
    };
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 100,
      slidesToShow: this.props.mobile ? 1 : 3,
      slidesToScroll: 1,
    };
    return (
      <NewsSliderWrapper mobile={this.props.mobile}>
        <SliderContainer mobile={this.props.mobile}>
          <Slider ref={(c) => (this.slider = c)} {...settings}>
            {this.state.data.nodes.map((article) => (
              <NewsArticle
                article={article}
                /*  width="90%" */
                margin="0"
                key={article.id}
                mobile={this.props.mobile}
              />
            ))}
          </Slider>
        </SliderContainer>
        {!this.props.mobile && (
          <ButtonsWrapper>
            <Link to="/news/">
              <Button
                content="See all news"
                font="1.8rem"
                margin="0 16px 0 0"
                bg="--ultramarine"
              />
            </Link>
            <div
              className="button prev"
              onClick={this.previous}
              onKeyDown={this.previous}
              role="button"
              tabIndex={0}
            >
              <FontAwesomeIcon icon="arrow-left" size="2x" />
            </div>
            <div
              className="button next"
              onClick={this.next}
              onKeyDown={this.previous}
              role="button"
              tabIndex={0}
            >
              <FontAwesomeIcon icon="arrow-right" size="2x" />
            </div>
          </ButtonsWrapper>
        )}
      </NewsSliderWrapper>
    );
  }
}
