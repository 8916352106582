import React from "react";
import styled from "styled-components";
import ContentPage from "../contentPage";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import SalaryGraph from "./salary-graph";

const Oval = styled.div`
  height: 45vw;
  width: 22.5vw;
  border-top-left-radius: 22.5vw;
  border-bottom-left-radius: 22.5vw;
  position: absolute;
  right: 0;
  background-color: #f7f8ff;
`;

const ImgWrapper = styled.div`
  width: 38%;

  position: absolute;
  right: 0;

  .europe {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const AreaOfActionContainer = styled.div`
  display: flex;

  .left-container {
    width: ${(props) => (props.mobile ? "100%" : "55%")};
  }

  h2 {
    margin-bottom: 1.6rem;
  }

  .text {
    color: ${(props) => (props.mobile ? "var(--cobalt)" : "")};
    text-align: ${(props) => (props.mobile ? "center" : "")};
    margin-bottom: ${(props) => (props.mobile ? "3.2rem !important" : "")};
  }
`;

const AreaOfActionContent = ({ mobile }) => {
  const data = useStaticQuery(query);
  return (
    <ContentPage mobile={mobile}>
      <div id="areaofaction_content" className="sectionWrapper">
        {!mobile && <Oval></Oval>}
        {!mobile && (
          <ImgWrapper>
            <StaticImage
              src="../../../images/europe@3x.png"
              className="europe"
              alt="Europe map image"
            />
          </ImgWrapper>
        )}
        <AreaOfActionContainer mobile={mobile}>
          <div className="left-container">
            <h3>Area of action</h3>
            <h2>Which countries hire in Portugal?</h2>
            <p className="p3 text">
              Portugal is a competitive, innovative and attractive country. With
              a strategic geographic location: Spain, France and Germany are
              some of the countries whose proximity is great. Compare average
              salaries at each location.{" "}
            </p>
            <SalaryGraph data={data} />
          </div>
        </AreaOfActionContainer>
      </div>
    </ContentPage>
  );
};

export default AreaOfActionContent;
const query = graphql`
  query SalariesQuery {
    allStrapiSalaries(sort: { fields: created_at }) {
      nodes {
        salary
        country {
          country
        }
        updated_at
        id
        job_position {
          position
        }
      }
    }
  }
`;
