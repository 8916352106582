import React from "react";
import styled from "styled-components";
import WhyPortugalSlider from "./whyportugalSlider";
import ContentPage from "../contentPage";
import { useStaticQuery, graphql } from "gatsby";

const WhyPortugalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 3.6rem;
    text-align: center;
  }

  .p2,
  .p3 {
    margin-bottom: 0;
  }
`;

const WhyPortugalContent = ({mobile}) => {
  const data = useStaticQuery(query);
  return (
    <ContentPage mobile={mobile}>
      <div id="whyportugal_content" className="sectionWrapper">
        <WhyPortugalContainer>
          <h3>Why Portugal?</h3>
          <h2>5 reasons to choose Portugal</h2>
          <WhyPortugalSlider mobile={mobile} data={data} />
        </WhyPortugalContainer>
      </div>
    </ContentPage>
  );
};

export default WhyPortugalContent;

const query = graphql`
  query WhyPortugalQuery {
    talentImg: file(
      relativePath: { eq: "whyportugal/nearshore-talent@3x.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    peaceImg: file(
      relativePath: { eq: "whyportugal/nearshore-peace@3x.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    englishImg: file(
      relativePath: { eq: "whyportugal/nearshore-english@3x.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    beachImg: file(
      relativePath: { eq: "whyportugal/nearshore-beach@3x.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    beerImg: file(
      relativePath: { eq: "whyportugal/nearshore-beer@3x.webp" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
