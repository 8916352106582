import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import ContentPage from "../contentPage";

const NearshoreCard = styled.div(
  (props) => `
    width: 25%;
    height: fit-content;
    padding: 24px;
    border-radius: 14px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
    background-color: ${props.background};
    z-index: 1;
  
    h3 {
      font-size: 1.6rem;
      font-weight: bold;
      color: ${props.color};
      margin-bottom: 16px;
    }
  
    .onshore{
      color: var(--green);
    }
    .offshore{
      color: var(--red);
    }
    .nearshore{
      color: var(--blue);
    }
    p {
      font-size: 1.6rem;
      margin: 0;
    }
  
    span{
      width: 56px;
      height: 1px;
      display: block;
      background-color: #eceeff;
      margin: 1.6rem 0;
    }
  `
);

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgWrapper = styled.div`
  width: 80%;
  z-index: -1;
  transform: translateX(20px);
`;

const NearShoreContent = () => {
  return (
    <ContentPage>
      <div id="nearshore_content" className="sectionWrapper">
        <h3>Nearshore</h3>
        <h2>
          A jump in the era of digital <br />
          transformation
        </h2>
        <p className="p3">
          Nearshore is a type of outsourcing practised by a large amount of
          companies in the technology field. It’s strategy usually involves
          clients in larger economies working with various providers in smaller
          nations in a nearby country. They often share a border, aswell as some
          other things, such as the time zone, culture or social linkages.
        </p>
        <CardsWrapper>
          <ImgWrapper>
            <StaticImage
              src="../../../images/img-nearshore@3x.webp"
              alt="Nearshore image"
            />
          </ImgWrapper>
          <NearshoreCard background="white" color="#575757">
            <h3 className="onshore">Onshore</h3>
            <p>
              Lives in <strong>Portugal</strong> and has a team in{" "}
              <strong>Portugal</strong>
            </p>
            <span></span>
            <h3 className="offshore">Offshore</h3>
            <p>
              Lives in <strong>Canada</strong> and has a team in{" "}
              <strong>Portugal</strong>
            </p>
            <span></span>
            <h3 className="nearshore">Nearshore</h3>
            <p>
              Lives in <strong>Germany</strong> and has a team in{" "}
              <strong>Portugal</strong>
            </p>
          </NearshoreCard>
        </CardsWrapper>
      </div>
    </ContentPage>
  );
};

export default NearShoreContent;
