import React from "react";
import styled from "styled-components";

const AdvCard = styled.div`
  outline: none !important;
  width: 12rem;
  padding: 2.4rem;
  border-radius: 14px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.12);
  border: ${(props) => (props.active ? "solid 4px #e8eaff" : "none")};
  margin: ${(props) => (props.active ? "-4px -4px 20px -4px" : "0 0 24px 0")};
  display: flex;
  flex-direction: column;
  background-image: ${(props) =>
    props.active
      ? "linear-gradient(to bottom, #d4dbff, var(--pastel-blue))"
      : ""};
  background-color: ${(props) => (props.active ? "" : "white")};
  cursor: pointer;

  &:hover {
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.15);
  }

  .stick {
    width: 15%;
    border: solid 3px
      ${(props) => (props.active ? "white" : `var(--pastel-blue)`)};
    border-radius: 4px;
  }

  .image {
    width: 3vw;
    height: 3vw;
    background-image: ${(props) =>
      props.active
        ? "none"
        : `linear-gradient(to bottom, #d4dbff, var(--pastel-blue)),
      linear-gradient(to bottom, var(--pastel-blue), var(--pastel-blue));`};
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 1.6rem !important;
    font-weight: 500;
    margin: 20px 0 10px 0 !important;
    color: ${(props) => (props.active ? "white" : `var(--pastel-blue)`)};
    max-width: 100px;
  }
  img {
    width: 60%;
    height: 60%;
  }
`;

const Card = ({ image, text, active, click }) => {
  return (
    <div
      onClick={click}
      onKeyDown={click}
      role="button"
      tabIndex="0"
      className="advCard"
    >
      <AdvCard active={active}>
        <div className="image">
          <img src={image} alt={text + " icon"}></img>
        </div>
        <p>{text}</p>
        <div className="stick"></div>
      </AdvCard>
    </div>
  );
};

export default Card;
