import LandingContent from "../../components/homePage/landing/landingContent";
import NearShoreContent from "../../components/homePage/nearshore/nearshore";
import AdvantagesContent from "../../components/homePage/advantages/advantages";
import WhyPortugalContent from "../../components/homePage/whyportugal/whyportugal";
import NewsSliderContent from "../../components/homePage/newsSlider/news";
import Footer from "../../components/homePage/footer";
import { StaticImage } from "gatsby-plugin-image";
import SideBar from "../../components/homePage/sidebar";
import "../../components/font-awesome";
import * as React from "react";
import styled from "styled-components";
import AreaOfActionContent from "../homePage/areaOfAction/areaOfAction";

const Container = styled.div`
  display: flex;
  height: 100%;
  margin-top: 8rem;
  scroll-behavior: smooth;
`;

const MainContent = styled.div`
  flex: 1;

  .sectionWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rectangle {
    position: absolute;
    z-index: -1;
    transform: translateY(-23rem);
  }
`;

const DesktopHome = () => {
  return (
    <div className="desktopHome">
      <Container>
        <SideBar legislation={false}/>
        <MainContent>
          <LandingContent />
          <NearShoreContent />
          <StaticImage
            src="../../images/rectangle-home@3x.png"
            className="rectangle"
            alt="rectangle shape"
          ></StaticImage>
          <AdvantagesContent />
          <WhyPortugalContent />
          <AreaOfActionContent />
          <NewsSliderContent />
          <Footer />
        </MainContent>
      </Container>
    </div>
  );
};

export default DesktopHome;
