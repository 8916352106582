import React from "react";
import ContentPage from "../contentPage";
import NewsSlider from "./newsSlider";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import NewsPreview from "./newsPreview";

const NewsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
`;

const NewsSliderContent = ({ mobile }) => {
  const data = useStaticQuery(query);
  return (
    <ContentPage
      background="var(--background2)"
      /* smaller={true} */ mobile={mobile}
    >
      <div id="news_content" className="sectionWrapper">
        <NewsContentWrapper>
          <h3>Explore</h3>
          <h2>Latest News</h2>
          <NewsPreview data={data.news} mobile={mobile} />
          {/* <NewsSlider data={data.news} mobile={mobile} /> */}
        </NewsContentWrapper>
      </div>
    </ContentPage>
  );
};

export default NewsSliderContent;

const query = graphql`
  query MyQuery {
    news: allStrapiArticles(
      limit: 6
      sort: { fields: published_at, order: DESC }
    ) {
      nodes {
        slug
        id
        published_at
        by
        title
        summary
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
